@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.button {
  @apply bg-gray-300 text-black px-6 py-2 rounded-lg
            hover:bg-gray-500 transition-all duration-300;
}

.input {
  @apply border border-solid border-gray-500 rounded-sm px-2 py-1;
}
