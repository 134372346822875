.formGroup {
  @apply my-4;
  label {
    @apply block mb-2;
  }

  textarea,
  select,
  input[type="text"],
  input[type="date"] {
    @apply w-full border border-solid border-gray-200 rounded-md px-2;
    min-height: 2.75rem;
  }

  textarea {
    min-height: 5rem;
  }
}
