.table {
  --border-color: #dfdfdf;
  @apply w-full;
  table-layout: fixed;

  border-spacing: 0;
  border-top: 1px solid var(--border-color);

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid var(--border-color);
    /*border-right: 1px solid black;*/

    :last-child {
      border-right: 0;
    }
  }

  th {
    text-align: left;
  }
}
